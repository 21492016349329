import axios from 'axios';

export default {
  getStatus: function (route, jobName) {
    return axios.get(`/api/${route}/jobstatus/${jobName}`);
  },
  cancelJob: function (route, jobName) {
    return axios.delete(`/api/${route}/canceljob/${jobName}`);
  },
  deleteJob: function (route, jobName) {
    return axios.delete(`/api/${route}/deletejob/${jobName}`);
  },
};

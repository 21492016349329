<template>
  <div class="container-page">
    <p class="title-inner">ТЭС стран мира</p>
    <div class="mb-16">
      <ButtonStock
        :disabled="importInProgress"
        :title="!importInProgress ? 'Загрузить из Excel' : 'Загрузка...'"
        @click="importExcel()"
      />
    </div>
    <div>
      <JobStatus :options="jobOptions" />
    </div>
    <TecWorldTable :nn="nn" />
    <section>
      <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
        <ImportModal @doimport="doImport" @close="closeModal" :acceptType="acceptType" />
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import ImportModal from '@/components/modals/ImportModal';

  import JobStatus from '../../../common/components/JobStatus';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import Api from '../api/index';
  import TecWorldTable from '../components/TecWorldTable';

  export default {
    name: 'TecWorld',
    components: { JobStatus, ImportModal, ModalComponent, ButtonStock, TecWorldTable },
    mixins: [filtermanager],
    data() {
      return {
        acceptType: 'application/zip,application/x-zip,application/x-zip-compressed',
        route: 'tecworld',

        importInProgress: false,
        importModal: false,
        jobOptions: [
          {
            route: 'tecworld',
            name: 'TecWorldImport',
            title: 'Загрузка данных "ТЭС стран мира"',
            totalCount: 0,
            processedCount: 0,
            currentItem: '',
            percent: 0,
            status: '',
            duration: 0,
          },
        ],
        nn: 1,
      };
    },
    created() {},
    methods: {
      importExcel() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        Api.downloadFile(this.route, file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Процесс импорта успешно запущен.', 'success');
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      closeModal() {
        this.importModal = false;
      },
    },
    computed: {},
  };
</script>

<style lang="scss"></style>

<template>
  <div v-if="hasJobs">
    <h6>Ход выполнения операции</h6>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th>Операция</th>
            <th>Статус</th>
            <th>Прогресс</th>
            <th>Время</th>
            <th>Сообщение</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="job-list">
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.title }}</td>
            <td>{{ item.status }}</td>
            <td>{{ item.processedCount }} из {{ item.totalCount }} ({{ item.percent.toFixed(1) }}%)</td>
            <td>{{ item.duration.toFixed(2) }} сек.</td>
            <td class="pre">{{ item.errors?.join('\n') }}</td>
            <td>
              <IconComponent v-if="item.complete" @click="deleteJob(item)" name="delete" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import Api from '../api/jobs';
  import IconComponent from '../ui/IconComponent';

  export default {
    name: 'JobStatus',
    components: { IconComponent },
    props: {
      options: Array,
      pollInterval: {
        type: Number,
        default: 5000,
      },
    },
    data() {
      return {
        timerId: null,
        items: [],
      };
    },
    created() {},
    mounted() {
      this.timerId = setInterval(this.update, this.pollInterval);
    },
    beforeUnmount() {
      clearTimeout(this.timerId);
    },
    methods: {
      update() {
        this.options.forEach((opt, index) => {
          Api.getStatus(opt.route, opt.name).then((response) => {
            if (response.data) {
              if (this.items.length <= index) {
                this.items.push(opt);
              }
              let item = this.items[index];
              item.status = response.data.status;
              item.totalCount = response.data.totalCount;
              item.processedCount = response.data.processedCount;
              item.percent = response.data.percent;
              item.duration = response.data.duration;
              item.complete = response.data.complete;
              item.errors = response.data.errors;
            }
          });
        });
      },
      deleteJob(item) {
        Api.deleteJob(item.route, item.name).then(() => {
          this.items.splice(this.items.indexOf(item), 1);
        });
      },
    },
    watch: {
      options: function (value) {
        this.items = [];
        value.forEach((opt) => {
          this.items.push(opt);
        });
      },
    },
    computed: {
      hasJobs() {
        return !!this.items?.find((x) => x.status !== '');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-container {
    max-height: 216px;
    overflow-y: auto;
  }
  .job-list {
    td {
      vertical-align: top;
    }
  }
  .pre {
    white-space: pre;
  }
</style>
